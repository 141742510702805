'use client'
import { comscoreConfig } from '@/app/util/comscore'
import { IGif } from '@giphy/js-types'
import { Grid as SDKGrid } from '@giphy/react-components'
import dynamic from 'next/dynamic'
import { usePathname, useRouter } from 'next/navigation'
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import styled from 'styled-components'
import { desktopWidth } from 'ui/src/constants'
import { desktop } from 'ui/src/css'
import UAParserContext from '../../context/ua-parser'
import { relativeUrl } from '../../util/url'
import useClientRender from '../../util/use-client-render'
import { usePageViewTracking } from './use-page-view-tracking'

const GifOverlay = dynamic(() => import('./overlay/gif-overlay'), { ssr: false })

type Props = Pick<SDKGrid['props'], 'initialGifs' | 'fetchGifs' | 'onGifClick' | 'overlay'> & {
    gaTrackingUrl?: (page: number) => string
    noShowMessage?: ReactNode
    columnOffsets?: number[]
    width?: number
    columns?: number
}

type GifsContextProps = {
    gifs: IGif[] | undefined
    setGifs: Dispatch<SetStateAction<IGif[] | undefined>>
}

export const GifsContext = createContext({
    gifs: [],
    setGifs: () => {},
} as GifsContextProps)

const NoGifsMessage = styled.p`
    text-align: center;
    padding: 10px;
`

const defaultMobileWindowWidth = 375
const Grid = ({
    initialGifs,
    fetchGifs,
    gaTrackingUrl,
    width,
    columns,
    columnOffsets,
    onGifClick,
    overlay,
    noShowMessage,
}: Props) => {
    const [hasGifsToShow, setHasGifsToShow] = useState(true)
    const router = useRouter()
    const pathname = usePathname()
    const [externalGifs, setExternalGifs] = useState<IGif[] | undefined>()
    const [gifCount, setGifCount] = useState(0)
    usePageViewTracking(gaTrackingUrl, gifCount)
    const { deviceType, isBot } = useContext(UAParserContext)
    // for dynamic search
    // const { searchKey } = useContext(SearchContext)
    let { width: windowWidth } = useWindowSize(defaultMobileWindowWidth)

    // no way to do grids on mobile yet since we don't know window width on server
    const clientRender = useClientRender()
    const hasRendered = useRef(new Set([windowWidth]))
    const [mobileWidth, setMobileWidth] = useState(defaultMobileWindowWidth)
    useEffect(() => {
        if (hasRendered.current.size > 2) {
            // don't rerender automatically, wait till browser resize
            setMobileWidth(windowWidth)
        }
        hasRendered.current.add(windowWidth)
    }, [windowWidth])
    const canRender = clientRender || (isBot && /^(\/explore\/|\/$)/.test(pathname))
    // go by server ua parsing
    let isDesktop = deviceType === 'desktop'
    // switch to window width on client
    if (canRender) {
        isDesktop = windowWidth >= desktop.breakpointWidth
    }
    // fetch gifs in advance of 250px so you don't see the loader if you slow scroll
    const loaderConfig = useMemo(() => (canRender ? { rootMargin: '0px 0px 250px 0px' } : undefined), [canRender])

    const value = useMemo(() => ({ gifs: externalGifs, setGifs: setExternalGifs }), [externalGifs, setExternalGifs])
    const eagerLoadingIds = useMemo(() => initialGifs?.map((gif) => gif.id), [initialGifs])
    if (!hasGifsToShow) {
        return noShowMessage ? <NoGifsMessage>{noShowMessage}</NoGifsMessage> : null
    }
    return (
        <GifsContext.Provider value={value}>
            <SDKGrid
                columns={columns || (isDesktop ? 4 : 2)}
                width={width || (isDesktop ? desktopWidth : mobileWidth)}
                percentWidth={isDesktop ? undefined : '100%'}
                fetchGifs={async (offset) => {
                    const result = await fetchGifs(offset)
                    if (window.COMSCORE?.beacon) {
                        window.COMSCORE.beacon(comscoreConfig)
                        fetch('/comscore-page-view')
                    }
                    if (result.data.length === 0 && initialGifs?.length === 0) {
                        setHasGifsToShow(false)
                    }
                    return result
                }}
                externalGifs={externalGifs}
                initialGifs={initialGifs}
                eagerIds={eagerLoadingIds}
                onGifClick={(gif, event) => {
                    if (!onGifClick) {
                        event.preventDefault()
                        event.stopPropagation()
                        router.push(relativeUrl(gif.url))
                    } else {
                        onGifClick(gif, event)
                    }
                }}
                gutter={isDesktop ? 12 : undefined}
                overlay={overlay || (isDesktop ? GifOverlay : undefined)}
                hideAttribution
                loaderConfig={loaderConfig}
                onGifsFetched={(gifs) => {
                    setGifCount(gifs.length)
                    setExternalGifs(gifs)
                }}
                columnOffsets={columnOffsets}
            />
        </GifsContext.Provider>
    )
}

export default Grid
